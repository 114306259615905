import React from "react";
import { Trans, useTranslation } from "gatsby-plugin-react-i18next";
import { LinkText } from "../../templates/LinkText";

const ProjectManagement = () => {
  const { t } = useTranslation();
  return (
    <section className="services-details-area">
      <div className="container">
        <div className="row">
          <div className="col-lg-12 col-md-12">
            <div className="services-details-desc">
              <h3>{t("Service - Projectmanagement - Title - 1")}</h3>
              <p>
                <Trans
                  i18nKey="Service - Projectmanagement - Text - 1"
                  components={{
                    link_zev: <LinkText to="/zev" title="ZEV" />,
                  }}
                />
              </p>

              <div className="row align-items-center">
                <div className="col-lg-12 col-md-12">
                  <div className="content">
                    <ul>
                      <li>{t("Service - Projectmanagement - List - 1_1")}</li>
                      <li>{t("Service - Projectmanagement - List - 1_2")}</li>
                      <li>{t("Service - Projectmanagement - List - 1_3")}</li>
                      <li>{t("Service - Projectmanagement - List - 1_4")}</li>
                      <li>{t("Service - Projectmanagement - List - 1_5")}</li>
                      <li>{t("Service - Projectmanagement - List - 1_6")}</li>
                      <li>{t("Service - Projectmanagement - List - 1_7")}</li>
                    </ul>
                  </div>
                </div>
              </div>

              <h3>{t("Unsere Leistungen")}</h3>

              <p>{t("Service - Projectmanagement - Text - 2")}</p>

              <div className="content">
                <ul>
                  <li>{t("Service - Projectmanagement - List - 2_1")}</li>
                  <li>{t("Service - Projectmanagement - List - 2_2")}</li>
                  <li>{t("Service - Projectmanagement - List - 2_3")}</li>
                </ul>
              </div>
              <p>{t("Service - Projectmanagement - Text - 3")}</p>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default ProjectManagement;
